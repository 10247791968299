import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/workspaces/extie/src/templates/mdx-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Blog`}</h1>
    <p>{`Hi!`}</p>
    <p>{`My name is Taylor and I made Wrdie.`}</p>
    <p>{`Let me start by saying thank you for checking us out!`}</p>
    <p>{`I've been working on Wrdie and its engine for more than 3 years now so
it's very exciting for me to be able to finally share it with you.`}</p>
    <p>{`Wrdie came from my passion for creating interactive things and my desire to share those things with others.  `}</p>
    <p>{`The technology grew from an online text adventure that I was writing. After I'd finished the expansive prologue, I played what I had made and quickly came to the conclusion that I'm a better programmer than I am a writer. `}</p>
    <p>{`So I started with the engine I already had, added the ability for multiple users to make stories, created a drag and drop editor, built a site out of it, gave it a name, and Wrdie is the result. `}</p>
    <p>{`But now that it's launched, my work is far from over.`}</p>
    <p>{`I'm well aware of Wrdie's current deficiencies and limitations, and I'm very interested in hearing your feedback as well. As you read this, I'm already at work making upgrades. `}</p>
    <p>{`Here's a peek:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`True WYSIWYG editor`}</strong>{` - My first focus was the tag system, so I haven't had time to add support for the kind of editor controls you see in other sites. Things like text styles, fonts and font sizes, and embedded pictures should be added soon. `}</li>
      <li parentName="ul"><strong parentName="li">{`Command links`}</strong>{` - This is something that other IF platforms have.  Right now, commands and exits are only expressed as buttons at the bottom of the screen. I already have an idea on how Wrdie can also support hyperlinks within your text that run a command when clicked.`}</li>
      <li parentName="ul"><strong parentName="li">{`Social features`}</strong>{` - Story ratings, tags and tag following, author pages and author following, all of these are staples of a good online platform and things that I will be adding in the future.`}</li>
      <li parentName="ul"><strong parentName="li">{`More self-expression`}</strong>{` - Custom text and background colors are just the beginning. Text and headline fonts will follow next and eventually I would like to have many different page templates so that you can control where the text and buttons are on the page. `}</li>
    </ul>
    <p>{`So if you haven't noticed yet, I'm in this for the long haul. `}</p>
    <p>{`Wrdie faces a major chicken-and-egg problem: it needs stories to attract readers, and it needs readers to attract authors. My solution is to start by focusing on the authors. `}</p>
    <p>{`I want to make Wrdie the best interactive story platform on the planet and I need your help to do it.`}</p>
    <p>{`I would love it if you `}<a parentName="p" {...{
        "href": "/discover"
      }}>{` played a story `}</a>{`.`}</p>
    <p>{`And it would be really cool if you `}<a parentName="p" {...{
        "href": "/sign-in"
      }}>{`checked out the story editor for yourself`}</a>{`.`}</p>
    <p>{`I made a `}<a parentName="p" {...{
        "href": "/help"
      }}>{`getting started tutorial`}</a>{` that you can follow to learn the basics of a Wrdie story.`}</p>
    <p>{`Also, don't forget that there is a `}<a parentName="p" {...{
        "href": "https://twitter.com/WrdieOfficial"
      }}>{`twitter feed`}</a>{` to follow and a `}<a parentName="p" {...{
        "href": "https://www.facebook.com/WrdieOfficial"
      }}>{`facebook page`}</a>{`
to like. They both should help keep you up to date with the improvements that I make. I also made a `}<a parentName="p" {...{
        "href": "https://discord.gg/mcSKgr7K3C"
      }}>{`discord channel`}</a>{` that you can join to get help
and chat with me and others in the Wrdie community.`}</p>
    <p>{`If you have any good ideas for improvements or need to rant about something that's missing, check out the `}<a parentName="p" {...{
        "href": "https://productific.com/@Wrdie"
      }}>{`feature voting and suggestion page`}</a>{`.`}</p>
    <p>{`Or even better, my email inbox is always open at
`}<a parentName="p" {...{
        "href": "mailto:taylor@wrdie.com"
      }}>{`taylor@wrdie.com`}</a>{`.`}</p>
    <p>{`Again, thank you so much for visiting and I hope you like what you find.`}</p>
    <Box textAlign="right" mdxType="Box">
      <p>{`Sending you peace and love,`}<br />{`
Taylor`}</p>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      